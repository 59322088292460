import React from "react";

import { HeadingLevel } from "baseui/heading";
import { Paragraph1, Label1 } from "baseui/typography";
import { StyledLink } from "baseui/link";

import Page from "../../components/internal-page";

const Reports = () => {
  return (
    <Page title="Отчетность по действующим займам">
      <HeadingLevel>
        <Label1>
          Контроль за целевым использованием заёмщиками средств микрозаймов
        </Label1>
        <Paragraph1>
          <StyledLink
            href="https://drive.google.com/file/d/1df_wBXedAS8DoctOyC6_fkMtR0a9rM3h/view"
            target="_blank"
            rel="noopener noreferrer"
          >
            Форма отчета о целевом использовании займа
          </StyledLink>
        </Paragraph1>
        <Label1>
          Справка о среднесписочной численности и среднемесячной заработной
          плате работников
        </Label1>
        <Paragraph1>
          <StyledLink
            href="https://drive.google.com/file/d/15rweJyR3-x1gcMB8KjEevJvfY9Qk7pN4/view"
            target="_blank"
            rel="noopener noreferrer"
          >
            Форма справки о среднесписочной численности и среднемесячной заработной
            плате работников
          </StyledLink>
        </Paragraph1>
      </HeadingLevel>
    </Page>
  );
};

export default Reports;
